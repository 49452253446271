import React, { useState, useEffect, useCallback, useMemo } from 'react';
import FilmItem from "./FilmItem";
import ScheduleManager from './ScheduleManager';

const FilmList = () => {
  const [films, setFilms] = useState([]);
  const [allFilmScores, setAllFilmScores] = useState({});
  const [expandedFilmId, setExpandedFilmId] = useState(null);
  const [isSorted, setIsSorted] = useState(false);

  useEffect(() => {
    fetch('/api/films')
      .then(response => response.json())
      .then(data => {
        setFilms(data);
        const initialScores = data.reduce((acc, film) => {
          const filmId = film.id || film.title;
          const storedScore = localStorage.getItem(`film-score-${filmId}`);
          if (storedScore) {
            acc[filmId] = parseInt(storedScore, 10);
          }
          return acc;
        }, {});
        setAllFilmScores(initialScores);
      })
      .catch(error => console.error('Error fetching films:', error));
  }, []);

  const handleScoreChange = useCallback((filmId, score) => {
    setAllFilmScores(prevScores => ({
      ...prevScores,
      [filmId]: score
    }));
  }, []);

  const handleExpand = useCallback((filmId) => {
    setExpandedFilmId(prevId => prevId === filmId ? null : filmId);
  }, []);

  const getAllFilmScores = useCallback(() => {
    console.log('All Film Scores:', allFilmScores);
    return allFilmScores;
  }, [allFilmScores]);

  const sortedFilms = useMemo(() => {
    if (!isSorted) return films;
    return [...films].sort((a, b) => {
      const scoreA = allFilmScores[a.id || a.title] || 0;
      const scoreB = allFilmScores[b.id || b.title] || 0;
      return scoreB - scoreA;
    });
  }, [films, allFilmScores, isSorted]);

  const toggleSort = () => {
    setIsSorted(prev => !prev);
  };

  return (
    <>
      <div className="container sm:max-w-[800px] mx-auto p-4">
        <div className="mb-4 flex flex-wrap justify-between items-center gap-2">
          <button
            onClick={toggleSort}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isSorted ? "Restore Original Order" : "Sort by Rating (High to Low)"}
          </button>
          <button
            onClick={getAllFilmScores}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Log All Film Scores
          </button>
        </div>
        {sortedFilms.map(film => {
          const filmId = film.id || film.title;
          return (
            <FilmItem
              key={filmId}
              film={film}
              onScoreChange={handleScoreChange}
              isExpanded={expandedFilmId === filmId}
              onExpand={handleExpand}
            />
          );
        })}
      </div>
      <div className="w-full">
        <ScheduleManager films={films} allFilmScores={allFilmScores} />
      </div>
    </>
  );
};

export default FilmList;