import React from 'react';

const ScheduleTimeline = ({ scheduleData }) => {
  if (!scheduleData || typeof scheduleData !== 'object') {
    return <div>No schedule data available.</div>;
  }

  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getScoreColor = (score) => {
    const colors = {
      '1': 'bg-red-300',
      '2': 'bg-yellow-300',
      '3': 'bg-green-300',
      '4': 'bg-blue-300'
    };
    return colors[score] || 'bg-gray-300';
  };

  const getTravelTimeColor = (travelTime) => {
    if (travelTime === null || travelTime === 0) return 'bg-gray-100';
    if (travelTime <= 5) return 'bg-green-100';
    if (travelTime <= 15) return 'bg-yellow-100';
    return 'bg-red-100';
  };

  const renderFilmItem = (item) => (
    <div key={item.filmTitle} className="border rounded-lg overflow-hidden mb-2">
      <div className={`p-2 ${getScoreColor(item.score)}`}>
        <div className="font-semibold">{item.filmTitle}</div>
        <div className="text-sm">
          {item.startTime} - {item.endTime}
        </div>
      </div>
      <div className={`p-2 text-sm ${getTravelTimeColor(item.travelTime)}`}>
        <div>{item.location}</div>
        {item.travelTime !== null && item.travelTime !== 0 && (
          <div>Travel time: {item.travelTime.toFixed(1)} min</div>
        )}
      </div>
    </div>
  );

  const days = Object.keys(scheduleData).sort();

  return (
    <div className="overflow-x-auto mt-4">
      <div className="flex space-x-4 p-4">
        {days.map(day => (
          <div key={day} className="flex-shrink-0 w-64">
            <h3 className="font-bold mb-2">{formatDate(day)}</h3>
            <div className="space-y-2">
              {scheduleData[day].map(renderFilmItem)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleTimeline;