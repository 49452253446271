import React, { useState, useCallback } from 'react';
import ScheduleTimeline from './ScheduleTimeline';

const ScheduleManager = ({ films, allFilmScores }) => {
  const [schedules, setSchedules] = useState([]);
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitSchedule = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const schedule = {
      4: [],
      3: [],
      2: [],
      1: []
    };

    films.forEach(film => {
      const score = allFilmScores[film.title];
      if (score && score >= 1 && score <= 4) {
        schedule[score].push(film.title);
      }
    });

    try {
      const response = await fetch('/api/schedule', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: schedule }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit schedule');
      }

      const data = await response.json();
      setSchedules(Array.isArray(data) ? data : [data]);
      setSelectedScheduleIndex(0);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [films, allFilmScores]);

  const handleScheduleChange = (event) => {
    setSelectedScheduleIndex(Number(event.target.value));
  };

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-4">Film Festival Schedule</h2>
      {error && <div className="text-red-500 mb-4">Error: {error}</div>}
      <button
        onClick={submitSchedule}
        disabled={isLoading}
        className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 disabled:bg-gray-400"
      >
        {isLoading ? 'Submitting...' : 'Submit and Load Schedule'}
      </button>
      {schedules.length > 0 && (
        <div className="mt-4">
          <label htmlFor="scheduleSelect" className="block text-sm font-medium text-gray-700">
            Select Schedule:
          </label>
          <select
            id="scheduleSelect"
            onChange={handleScheduleChange}
            value={selectedScheduleIndex}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            {schedules.map((_, index) => (
              <option key={index} value={index}>
                Schedule {index + 1}
              </option>
            ))}
          </select>
        </div>
      )}
      {schedules.length > 0 ? (
        <ScheduleTimeline scheduleData={schedules[selectedScheduleIndex]} />
      ) : (
        <div className="mt-4">Submit the schedule to view the timeline.</div>
      )}
    </div>
  );
};

export default ScheduleManager;