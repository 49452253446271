import React, { useState, useCallback, useRef, useEffect } from 'react';

const ScoreSelector = ({ onChange, filmId }) => {
  const [node, setNode] = useState(() => {
    const savedScore = localStorage.getItem(`film-score-${filmId}`);
    return savedScore !== null ? parseInt(savedScore, 10) : 1;
  });
  const [nodePositions, setNodePositions] = useState(null);
  const [pegPosition, setPegPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  const nodes = 5;
  const emojis = ['😞', '😐', '🙂', '😃', '🤩'];
  const colors = ['#FF4136', '#FF851B', '#FFDC00', '#7FFF00', '#2ECC40'];

  const handleMouseMove = useCallback((e) => {
    if (nodes < 2 || !nodePositions) return;

    const startX = containerRef.current.getBoundingClientRect().left;
    const mousePosition = e.clientX - startX;
    const closestNode = nodePositions.reduce((prev, curr) =>
      Math.abs(curr - mousePosition) < Math.abs(prev - mousePosition) ? curr : prev
    );

    const newNode = nodePositions.indexOf(closestNode);
    setNode(newNode);
    setPegPosition(closestNode);
  }, [nodePositions, nodes]);

  const handleMouseDown = useCallback((e) => {
    if (e.button !== 0) return;

    setIsDragging(true);
    e.preventDefault();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleNodeClick = useCallback((index) => {
    setNode(index);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useEffect(() => {
    if (containerWidth !== null) {
      const width = containerWidth;
      const spacing = width / (nodes - 1);
      setNodePositions(Array.from({ length: nodes }, (_, n) => n * spacing));
    }
  }, [containerWidth, nodes]);

  useEffect(() => {
    if (nodePositions) {
      setPegPosition(nodePositions[node]);
      const score = node;
      localStorage.setItem(`film-score-${filmId}`, score.toString());
      onChange(score);
    }
  }, [node, nodePositions, onChange, filmId]);

  return (
    <div
      className="w-full min-w-md mx-auto my-auto bg-gray-600 p-8 rounded-full"
      style={{cursor: isDragging ? 'grabbing' : ''}}
    >
      <div className="relative">
        <div
          ref={containerRef}
          className="relative h-2 rounded-full"
          style={{ background: `linear-gradient(to right, ${colors.join(',')})` }}
        >
          {nodePositions && nodePositions.map((pos, index) => (
            <React.Fragment key={index}>
              <button
                className="absolute w-4 h-4 bg-white rounded-full border-2 border-gray-300 transform -translate-x-1/2 -translate-y-1/2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 pointer-events-none"
                style={{
                  left: `${pos}px`,
                  top: '50%',
                }}
              />
              <div
                className="absolute w-12 h-12"
                style={{
                  left: `${pos}px`,
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  cursor: isDragging ? 'grabbing' : 'pointer',
                }}
                onClick={() => handleNodeClick(index)}
              />
            </React.Fragment>
          ))}
        </div>
        <div
          className="absolute w-10 h-10 cursor-pointer flex items-center justify-center text-3xl"
          style={{
            left: `${pegPosition}px`,
            top: '-20px',
            transform: 'translateX(-50%)',
            userSelect: 'none',
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={handleMouseDown}
        >
          {emojis[node]}
        </div>
      </div>
    </div>
  );
};

export default ScoreSelector;