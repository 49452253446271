import React, { useCallback } from 'react';
import ScoreSelector from "./ScoreSelector";

const FilmItem = ({ film, onScoreChange, isExpanded, onExpand }) => {
  const filmId = film.id || film.title;

  const handleScoreChange = useCallback((score) => {
    onScoreChange(filmId, score);
  }, [filmId, onScoreChange]);

  const toggleExpand = () => {
    onExpand(filmId);
  };

  const renderFilmDetails = () => {
    const otherParams = [
      "director",
      "subtitles",
      "info",
      "screenwriters",
      "cast",
      "countries",
      "year",
      "curator",
      "duration",
      "courtesy",
      "language"
    ];

    return (
      <div className="p-4 bg-gray-100">
        <div className="mb-4">
          <p>{film.description}</p>
        </div>

        {renderMedia()}

        <div className="flex flex-col md:flex-row mt-4">
          <div className="w-full md:w-1/2 pr-4">
            <p>{film.synopsis}</p>
          </div>
          <div className="w-full md:w-1/2 mt-4 md:mt-0">
            <ul>
              {otherParams.map((param) => (
                film[param] && (
                  <li key={param} className="mb-1">
                    <span className="font-medium">{param.charAt(0).toUpperCase() + param.slice(1)}:</span> {film[param]}
                  </li>
                )
              ))}
              {film.url && (
                <li className="mb-1">
                  <a href={film.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    BFI site
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderMedia = () => {
    if (film.image_src) {
      return <img src={film.image_src} alt={film.title} className="w-full h-auto mt-4 rounded-lg" />;
    } else if (film.embed_src) {
      return (
        <div className="mt-4 relative" style={{ paddingTop: '56.25%' }}>
          <iframe
            src={film.embed_src}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-lg"
          ></iframe>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden">
      <div className="flex flex-col sm:flex-row">
        <div
          className="w-full sm:w-[30%] sm:min-w-[200px] p-4 flex items-center bg-gray-100 cursor-pointer hover:bg-gray-200 transition-colors"
          onClick={toggleExpand}
        >
          <span className="font-medium">{film.title}</span>
        </div>
        <div className="w-full sm:w-[70%] sm:max-w-[600px] p-4 bg-gray-150">
          <ScoreSelector onChange={handleScoreChange} filmId={filmId} />
        </div>
      </div>
      {isExpanded && renderFilmDetails()}
    </div>
  );
};

export default FilmItem;