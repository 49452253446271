import React, { useState, useEffect, useCallback } from 'react';
import FilmList from "./FilmList";

function App() {

  return (
    <div className="p-4 space-y-4">
      <FilmList/>
    </div>
  );
}

export default App;

  // const ratingsConfig = {
  //   productQuality: { label: "Product Quality", nodes: 5 },
  //   customerService: { label: "Customer Service", nodes: 7 },
  //   deliverySpeed: { label: "Delivery Speed", nodes: 3 },
  //   valueForMoney: { label: "Value for Money", nodes: 5 },
  // };
  //
  // const [ratings, setRatings] = useState(
  //   Object.keys(ratingsConfig).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})
  // );
  //
  // useEffect(() => {
  //   console.log('Ratings updated:', ratings);
  // }, [ratings]);
  //
  // const updateRating = useCallback((key, newValue) => {
  //   setRatings(prevRatings => {
  //     if (prevRatings[key] !== newValue) {
  //       return { ...prevRatings, [key]: newValue };
  //     }
  //     return prevRatings;
  //   });
  // }, []);